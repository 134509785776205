<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.address"
                        name="address"
                        label="外出地点"
                        placeholder="外出地点"
                        :rules="[{ required: true, message: '请填写外出地点' }]"
                    />
                    <van-cell title="外出时长">
                        <template #right-icon>
                            <van-stepper
                                v-model="details.duration"
                                :min="0"
                                :max="99"
                                :step="0.5"
                                :disable-input="true"
                                theme="round"
                            />小时
                        </template>
                    </van-cell>
                    <van-cell title="开始时间" :value="details.start_time" @click="start_time_show = true"/>
                    <time-component
                        :fshow="start_time_show"
                        title="选择时间"
                        @close_time="close_start_time"
                        @submit_time="submit_start_time"
                    ></time-component>
                    <van-cell title="结束时间" :value="details.end_time" @click="end_time_show = true"/>
                    <time-component
                        :fshow="end_time_show"
                        title="选择时间"
                        @close_time="close_end_time"
                        @submit_time="submit_end_time"
                    ></time-component>
                    <van-field
                        type="textarea"
                        v-model="details.content"
                        name="content"
                        label="外出原因"
                        placeholder="外出原因"
                        :rules="[{ required: true, message: '请填写外出原因' }]"
                    />
                    <van-cell title="上传附件">
                        <template #label>
                            <uploader-component
                                :farr="details.other"
                                url="go_out/upload"
                                @update_img="update_img"
                            ></uploader-component>
                        </template>
                    </van-cell>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="外出单号" :value="details.name" />
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell title="外出地点" :value="details.address" />
                <van-cell title="外出时长" :value="details.duration+'小时'" />
                <van-cell title="开始时间" :value="details.start_time" />
                <van-cell title="结束时间" :value="details.end_time" />
                <van-cell title="外出原因" :value="details.content" />
                <van-cell title="申请人" :value="details.create_staff_name" />
                <van-cell title="申请日期" :value="details.apply_date" />
                <van-cell title="附件">
                    <template #label>
                        <uploader-component
                            :farr="details.other"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="withdraw_auth" round block type="danger" @click="GoOutWithdraw()">审批撤回</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="edit_auth" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="del_auth" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'
import TimeComponent from '@/components/common/TimeComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { 
    go_out_details_request,
    go_out_del_request,
    go_out_edit_request,
    go_out_add_request,
    go_out_to_approval_request,
    go_out_withdraw_request
    } from '@/network/hrm/GoOut.js'
import { staff_list_request } from '@/network/list.js'

export default {
    name:'GoOutDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                address: '',
                duration: '',
                start_time: '',
                end_time: '',
                content: '',
                other: []
            },
            start_time_show: false,
            end_time_show: false,
            edit: false,
            all_staff: [],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改外出单' : '新增外出单') : '外出单详情'
        }
    },
    methods:{
        close_start_time() {
            this.start_time_show = false
        },
        submit_start_time(time) {
            this.details.start_time = time
            this.start_time_show = false
        },
        close_end_time() {
            this.end_time_show = false
        },
        submit_end_time(time) {
            this.details.end_time = time
            this.end_time_show = false
        },
        get_go_out_details() {
            this.$store.commit('true_loading')
            go_out_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                go_out_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        back_details() {
            this.get_go_out_details()
            this.edit = false
        },
        edit_details() {
            if(!this.details.duration) {
                this.$toast.fail('外出时长不能为0')
                return
            }

            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                address: this.details.address,
                duration: this.details.duration,
                start_time: this.details.start_time,
                end_time: this.details.end_time,
                content: this.details.content,
                other: this.details.other
            }
            go_out_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_go_out_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            if(!this.details.duration) {
                this.$toast.fail('外出时长不能为0')
                return
            }
            
            this.$store.commit('true_loading')
            let data = {}
            data = {
                address: this.details.address,
                duration: this.details.duration,
                start_time: this.details.start_time,
                end_time: this.details.end_time,
                content: this.details.content,
                other: this.details.other
            }
            go_out_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_go_out_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 11,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                go_out_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_go_out_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        GoOutWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                go_out_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_go_out_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_staff(e) {
            this.details.staff_id = e.id
        },
        update_img(arr) {
            this.details.other = arr
        },
        is_auth() {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(this.details.state === 0) {
                //判断创建人是否为自己
                if(this.details.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交外出申请')
                    this.edit_auth = this.$_has('修改外出申请')
                    this.del_auth = this.$_has('删除外出申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if(this.details.create_staff === this.$store.state.user.id) {
                    this.withdraw_auth = this.$_has('撤回外出申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批外出申请')
                }
                this.sel_approval_auth = this.$_has('查看外出申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看外出申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if(this.details.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交外出申请')
                    this.edit_auth = this.$_has('修改外出申请')
                    this.del_auth = this.$_has('删除外出申请')
                }
                this.sel_approval_auth = this.$_has('查看外出申请审批记录')
            }
        },
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id
        this.get_all_staff()

        if(this.id) {
            this.get_go_out_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent,
        TimeComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>